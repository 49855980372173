<template>
  <v-row class="mt-3">
    <v-container>
      <v-card>
        <v-card-title class="d-flex justify-content-between">
          <h4>{{ $t("billing") }}</h4>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-select
              :items="[
                'Pagos recibidos por trabajos',
                'Pagos hechos por trabajos de otros',
                'Pagos a prosk - suscripcion',
              ]"
              label="Forma de pago"
            ></v-select>
            <v-text-field
              label="Nombre o razon social:"
              required
            ></v-text-field>
            <v-text-field label="CFDI:" required></v-text-field>
            <v-text-field label="Domicilio fiscal:" required></v-text-field>
            <v-text-field label="Codigo postal:" required></v-text-field>
            <v-text-field label="Email:" required></v-text-field>
            <v-text-field label="Telefono:" required></v-text-field>
            <v-text-field label="" required></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn class="btn btn-primary float-end mt-2 mt-md-0">Submit</v-btn>
          <v-btn class="btn btn-danger float-end mt-2 mt-md-0">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-row>
</template>
<script>
export default {
  name: 'Billing'
};
</script>
